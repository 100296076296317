<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="First Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="First Name" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="Fisrt Name"
                      v-model="model.firstName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Last Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Last Name" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="Last Name"
                      v-model="model.lastName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Email Address"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Email Address" label-for="mc-name">
                    <b-form-input
                      id="mc-email"
                      placeholder="Email Address"
                      v-model="model.username"
                      :readonly="$route.meta.action == 'edit'"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Role"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Role" label-for="mc-role">
                    <select class="form-control" v-model="model.roleId">
                      <option
                        v-for="(i, index) in roles"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6" v-if="$route.meta.action == 'store'">
                <ValidationProvider
                  name="Password"
                  :rules="
                    $route.meta.action == 'store' ? 'required|min:6' : 'min:6'
                  "
                  vid="confirmation"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Password" label-for="reset-password-new">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="reset-password-new"
                        v-model="model.password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6" v-if="$route.meta.action == 'store'">
                <ValidationProvider
                  name="Confirmation Password"
                  :rules="
                    $route.meta.action == 'store' || model.password != null
                      ? 'required|min:6|confirmed:confirmation'
                      : 'min:6|confirmed:confirmation'
                  "
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label-for="reset-password-confirm"
                    label="Confirm Password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="model.confirmPass"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false : null"
                        name="reset-password-confirm"
                        placeholder="Confirmation Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Mobile Phone"
                  :rules="{
                    regex: /^(\+62|62|0)8[1-9][0-9]{6,11}$/,
                    required: true,
                  }"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Mobile Phone" label-for="mc-no-telepon">
                    <b-form-input
                      id="mc-no-telepon"
                      placeholder="Mobile Phone"
                      v-model="model.mobilePhone"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- <b-col md="6">
                <ValidationProvider
                  name="Tipe Pertanyaan"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Tipe Pertanyaan" label-for="mc-type">
                    <b-form-radio-group
                      v-model="model.type"
                      :options="typeOptions"
                      class="mt-1"
                      value-field="value"
                      text-field="text"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col> -->
              <b-col md="6">
                <ValidationProvider
                  name="Image Profile"
                  :rules="
                    $route.meta.action == 'store'
                      ? 'required|ext:jpeg,png,jpg|size:1000'
                      : 'ext:jpeg,png,jpg|size:1000'
                  "
                  v-slot="{ errors }"
                >
                  <b-form-group label="Image Profile" label-for="mc-icon">
                    <b-form-file
                      v-model="ava"
                      @change="avatar_change"
                      placeholder="Choose a file..."
                    />
                    <span class="font-italic" style="font-size: 12px">
                      * Format File (jpeg, jpg, png) Maximum Size File (1 Mb)
                    </span>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row> </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'admin' }"
              >
                Cancel
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}

.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import { password } from "@/@core/utils/validations/validations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  data() {
    return {
      errors: {},
      showDismissibleAlert: false,
      ava: null,
      config: {
        api: "admins",
        role: "roles",
        redirect: "admin",
        upload: "misc/upload",
      },
      model: {
        username: "",
        password: "",
        profile: {
          avatar: "",
        },
      },
      password1FieldType: "password",
      password2FieldType: "password",
      messages: "",
      roles: [],
    };
  },
  mounted() {
    let _ = this;
    _.getRoles();
    _.getData();
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    avatar_change(e) {
      let _ = this;
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          console.log(res.data.uploadedFile);
          _.model.avatar = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });

      // this.model.avatar = e.target.files[0].name;
      console.log(this.model.avatar);
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data;
            _.model.lastName = _.model.profile.lastName;
            _.model.firstName = _.model.profile.firstName;
            _.model.mobilePhone = _.model.profile.phoneNumber;
            _.model.avatar = _.model.profile.avatar;
            _.model.roleId = _.model.role.id;
          });
      }
    },
    getRoles() {
      let _ = this;
      axios.get(_.config.role).then((response) => {
        let _ = this;
        _.roles = response.data.rows;
      });
    },
    save() {
      let _ = this;

      if (this.$route.meta.action == "store") {
        let data_post = {
          username: _.model.username,
          password: _.model.password,
          profile: {
            firstName: _.model.firstName,
            lastName: _.model.lastName,
            phoneNumber: _.model.mobilePhone,
            avatar: _.model.avatar,
          },
          roleId: parseInt(_.model.roleId),
        };
        axios
          .post(_.config.api, data_post)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: "Data has been saved ",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        let data_post = {
          profile: {
            firstName: _.model.firstName,
            lastName: _.model.lastName,
            phoneNumber: _.model.mobilePhone,
            avatar: _.model.avatar,
          },
          roleId: parseInt(_.model.roleId),
        };
        axios
          .patch(_.config.api + "/" + _.$route.params.id, data_post)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Success",
                text: "Data has been Updated",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
};
</script>
